import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/auth'
import contract from '@/store/contract'
import template from '@/store/template'
import style from '@/store/style'
import invoice from '@/store/invoice'
import finance from '@/store/finance'
import user from '@/store/user'
import createLogger from 'vuex/dist/logger'
import common from "@/api/common";

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    breadcrumbsItems: []
  },
  getters: {
    breadcrumbsItems(state) {
      return state.breadcrumbsItems
    },
    templateTypesSelector() {
      return [
        {text: 'Invoice', value: 0},
        {text: 'Technical task', value: 1},
        {text: 'Act', value: 2}
      ]
    }
  },
  mutations: {
    updateBreadcrumbsItems(state, { items }) {
      state.breadcrumbsItems = items
    }
  },
  actions: {
    async getDictionary(_, payload) {
      return await common.getDictionary(payload.name)
    },
    async getBanks() {
      return await common.getBanks()
    },
    async getPaymentCategories(_, payload) {
      return await common.getPaymentCategories(payload.limit, payload.offset, payload.paymentType)
    }
  },
  modules: {
    auth,
    contract,
    template,
    style,
    invoice,
    user,
    finance
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
