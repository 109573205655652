import auth from '@/api/auth'

const getDefaultState = () => ({
    authLoading: false
})

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        isAuthenticated() {
            return !!localStorage.getItem(process.env.VUE_APP_TOKEN_STORAGE_KEY)
        },
        isAuthLoading(state) {
            return state.authLoading
        }
    },
    mutations: {
        initStart(state) {
            state.authLoading = true
        },
        initSuccess(state, {token}) {
            localStorage.setItem(process.env.VUE_APP_TOKEN_STORAGE_KEY, token)
        },
        finallyLoading(state) {
            state.authLoading = false
        },
        logout(state) {
            localStorage.removeItem(process.env.VUE_APP_TOKEN_STORAGE_KEY)
            Object.assign(state, getDefaultState())
        },
    },
    actions: {
        async signIn({commit}, payload) {
            commit('initStart')
            return await auth.signIn(payload)
        },
        logout({commit}) {
            commit('logout')
        }
    }
}
