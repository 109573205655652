import invoice from '@/api/invoice'

const getDefaultState = () => ({
    isLoading: false,
    invoices: [],
    invoiceId: null
})

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        invoices(state) {
            return state.invoices
        }
    },
    mutations: {
        setIsLoading(state, { value }) {
            state.isLoading = value
        },
        resetInvoices(state) {
            state.invoices = []
            state.invoiceId = null
        },
        updateInvoices(state, { results }) {
            state.invoices = results
        }
    },
    actions: {
        async getAllInvoices({commit}, payload) {
            commit('resetInvoices')
            commit('setIsLoading', {value: true})
            return await invoice.getAllInvoices(payload.limit, payload.offset)
        },
        async getInvoiceDetail({commit}, invoiceId) {
            commit('resetInvoices', invoiceId)
            commit('setIsLoading', {value: true})
            return await invoice.getInvoiceDetail(invoiceId)
        },
        async updateInvoiceStatus({commit}, {invoiceId, status}) {
            commit('setIsLoading', {value: true})
            return await invoice.updateInvoiceStatus(invoiceId, status)
        },
        async runAmountCalculation({commit}, {invoiceId, payload}) {
            commit('setIsLoading', {value: true})
            return await invoice.runAmountCalculation(invoiceId, payload)
        },
        async createNewInvoice({commit}, payload) {
            commit('setIsLoading', {value: true})
            return await invoice.createNewInvoice(payload)
        },
        async removeInvoice({commit}, invoiceId) {
            commit('setIsLoading', {value: true})
            return await invoice.removeInvoice(invoiceId)
        },
        async saveInvoiceDetail({commit}, {invoiceId, payload}) {
            commit('setIsLoading', {value: true})
            return await invoice.saveInvoiceDetail(invoiceId, payload)
        }
    }
}
