import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const requireAuthenticated = (to, from, next) => {
    if (!store.getters['auth/isAuthenticated']) {
        next({name: 'Login'})
    } else {
        next()
    }
}

const requireUnauthenticated = (to, from, next) => {
    if (store.getters['auth/isAuthenticated']) {
        next({name: 'Home'})
    } else {
        next()
    }
}

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {layout: 'main'},
        component: () => import('../views/Dashboard.vue'),
        beforeEnter: requireAuthenticated
    },
    {
        path: '/finance',
        name: 'Finance',
        meta: {layout: 'main'},
        component: () => import('../views/Finance.vue'),
        beforeEnter: requireAuthenticated
    },
    {
        path: '/invoices',
        name: 'Invoices',
        meta: {layout: 'main'},
        component: () => import('../views/Invoice.vue'),
        beforeEnter: requireAuthenticated,
    },
    {
        path: '/invoices-edit/:invoiceId',
        name: 'EditInvoices',
        meta: {layout: 'main'},
        props: true,
        component: () => import('../views/Invoice.vue'),
        beforeEnter: requireAuthenticated,
    },
    {
        path: '/contracts',
        name: 'Contracts',
        meta: {layout: 'main'},
        component: () => import('../views/Contract.vue'),
        beforeEnter: requireAuthenticated,
    },
    {
        path: '/templates',
        name: 'Templates',
        meta: {layout: 'main'},
        component: () => import('../views/Template.vue'),
        beforeEnter: requireAuthenticated,
    },
    {
        path: '/templates-edit/:templateId',
        name: 'EditTemplates',
        meta: {layout: 'main'},
        props: true,
        component: () => import('../views/Template.vue'),
        beforeEnter: requireAuthenticated,
    },
    {
        path: '/styles',
        name: 'Styles',
        meta: {layout: 'main'},
        component: () => import('../views/Style.vue'),
        beforeEnter: requireAuthenticated,
    },
    {
        path: '/styles-edit/:styleId',
        name: 'EditStyles',
        meta: {layout: 'main'},
        props: true,
        component: () => import('../views/Style.vue'),
        beforeEnter: requireAuthenticated,
    },
    {
        path: '/login',
        name: 'Login',
        meta: {layout: 'auth'},
        component: () => import('../views/Auth.vue'),
        beforeEnter: requireUnauthenticated
    },
    {
        path: '/profile-settings',
        name: 'ProfileSettings',
        meta: {layout: 'main'},
        component: () => import('../views/ProfileSettings.vue'),
        beforeEnter: requireAuthenticated,
    },
    {
        path: '/*',
        name: 'Page404',
        meta: {layout: 'main'},
        component: () => import('../views/Page404.vue'),
        beforeEnter: requireUnauthenticated
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
