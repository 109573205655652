import { session, setAuthHeader } from '@/api/session'

export default {
  getAllTemplates (limit, offset) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/template/?limit=${limit}&offset=${offset}`
    return session.get(url, setAuthHeader())
  },
  getTemplateDetail (templateId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/template/${templateId}/`
    return session.get(url, setAuthHeader())
  },
  saveTemplateDetail (templateId, payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/template/${templateId}/`
    return session.put(url, payload, setAuthHeader())
  },
  generatePreviewToPdf (templateId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/template/${templateId}/preview_to_pdf/`
    return session.get(url, setAuthHeader())
  },
  updateVersion (templateId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/template/${templateId}/update_version/`
    return session.post(url, {}, setAuthHeader())
  },
  loadPreviewHtml (templateId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/template/${templateId}/preview/`
    return session.get(url, setAuthHeader())
  },
  createTemplate (payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/template/`
    return session.post(url, payload, setAuthHeader())
  },
  removeTemplate (templateId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/template/${templateId}/`
    return session.delete(url, setAuthHeader())
  }
}
