<template>
  <v-container>
    <div class="mx-auto overflow-hidden">
      <v-app-bar
          app
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>
          <v-icon>mdi-finance</v-icon>
          <router-link
              :to="{ path: `/`}"
              style="text-decoration: none; color: black;"
          >
            <b> Finance Cabinet</b>
          </router-link>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
            bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-avatar v-bind="attrs" v-on="on" size="36px">
              <img v-if="userInfo.avatar" :src="userAvatarLink" alt="avatar">
              <v-icon v-else light>
                mdi-account-circle
              </v-icon>
            </v-avatar>
            <span v-bind="attrs" v-on="on" class="mr-2">
              <strong style="color: rgba(0, 0, 0, 0.6);">
                {{ userInfo.last_name }} {{ userInfo.first_name }}
              </strong>
            </span>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <img :src="userAvatarLink" alt="avatars">
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ userInfo.last_name }} {{ userInfo.first_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ userInfo.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list>
              <v-list-item @click.prevent="logout">
                <v-list-item-action>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-action>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-app-bar>

      <v-navigation-drawer
          v-model="drawer"
          fixed
          app
          color="#1e1e2d"
          width="256"
      >

        <v-list
            nav
            dense
            :dark="true"
            color="#1e1e2d"
            style="padding-top: 64px;"
        >
          <v-list-item-group
              v-model="group"
              active-class="deep-primary--text text--accent-4"
          >

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-chart-box-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left">
                <router-link
                    :to="{ name: 'Home'}"
                    class="router-link"
                >
                  Dashboard
                </router-link>
              </v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-piggy-bank</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left">
                <router-link
                    :to="{ name: 'Finance'}"
                    class="router-link"
                >
                  Finances
                </router-link>
              </v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-list-box-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left">
                <router-link
                    :to="{ name: 'Invoices'}"
                    class="router-link"
                >
                  Invoices
                </router-link>
              </v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-draw</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left">
                <router-link
                    :to="{ name: 'Contracts'}"
                    class="router-link"
                >
                  Contracts
                </router-link>
              </v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-group
                :value="false"
                prepend-icon="mdi-folder"
            >
              <template v-slot:activator>
                <v-list-item-title>
                  Templates
                </v-list-item-title>
              </template>

              <v-list-item class="pl-2">
                <v-list-item-icon>
                  <v-icon class="pl-1" :small=true>
                    mdi-file-document-multiple-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <router-link
                      :to="{ name: 'Templates'}"
                      class="router-link"
                  >
                    Templates
                  </router-link>
                </v-list-item-title>
              </v-list-item>

              <v-list-item class="pl-2">
                <v-list-item-icon>
                  <v-icon class="pl-1" :small=true>
                    mdi-palette-swatch-variant
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <router-link
                      :to="{ name: 'Styles'}"
                      class="router-link"
                  >
                    Styles
                  </router-link>
                </v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left">
                <router-link
                    :to="{ name: 'ProfileSettings'}"
                    class="router-link"
                >
                  Settings
                </router-link>
              </v-list-item-title>
            </v-list-item>

          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>

    <v-main>
      <v-breadcrumbs :items="breadcrumbsItems">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href">
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <v-container>
        <router-view/>
      </v-container>
    </v-main>

    <v-footer
        app
        fixed
        :inset="true"
        class="d-flex justify-center"
    >
      Developed by
      <a href="https://whylemur.com/"
         target="_blank"
         class="pl-2"
      >
        WhyLemur Software Company
      </a>
    </v-footer>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'MainLayout',
  computed: {
    ...mapActions(['auth/logout']),
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      breadcrumbsItems: 'breadcrumbsItems',
      userInfo: 'user/getShortInfo',
      userAvatarLink: 'user/getAvatarLink'
    })
  },
  data: () => ({
    drawer: true,
    menu: false,
    group: null
  }),
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
      window.location.replace('/login')
    },
  },
  created() {
    // load user info
    this.$store.dispatch('user/loadShortInfo')
  },
  watch: {}
}
</script>

<style scoped>
.router-link {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.text-left {
  text-align: left;
}
</style>
