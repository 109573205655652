import user from '@/api/user'
import {DEFAULT_USER_AVATAR_LINK, HOME_PHONE} from "@/tools/constants";
import {toFormAnyBackendLink} from "@/tools/helpers";

const getDefaultState = () => ({
    isLoading: false,
    shortInfo: {
        id: null,
        username: null,
        email: null,
        first_name: null,
        last_name: null,
        middle_name: null,
        full_name: null,
        profile_type: null,
        profile_type_value: null,
        avatar: null,
        address: null
    },
    statistics: [
        {
            key: 'income',
            name: 'Income',
            icon: 'mdi-arrow-up',
            color: 'green',
            value: '$4,500'
        },
        {
            key: 'invoices',
            name: 'Invoices',
            icon: 'mdi-arrow-up',
            color: 'green',
            value: '126'
        }
    ],
    profileBasicInfo: {
        id: null,
        email: null,
        first_name: null,
        last_name: null,
        middle_name: null,
        gender_type: null,
        gender_type_value: null,
        birthday: null,
        avatar: null,
    },
    profileEntrepreneurInfo: {
        id: null,
        name: null,
        itn: null,
        group_type: null,
        group_type_value: null,
        is_vat_payer: false,
        taxation_system: null,
        taxation_system_value: null,
        tax_rate: null,
        tax_rate_value: null,
        registration_date: null,
        registration_record_number: null,
        registration_number_of_single_payer: null,
        registration_address: null,
        economic_activities: [],
        economic_activities_detail: [],
        phones: []
    },
    bankAccounts: [],
    documents: [],
    files: []
})

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        getAvatarLink(state) {
            return state.shortInfo.avatar ? toFormAnyBackendLink(state.shortInfo.avatar) : DEFAULT_USER_AVATAR_LINK
        },
        getShortInfo(state) {
            return state.shortInfo
        },
        getStatistics(state) {
            return state.statistics
        },
        getProfileBasicInfo(state) {
            return state.profileBasicInfo
        },
        getProfileEntrepreneurInfo(state) {
            return state.profileEntrepreneurInfo
        },
        bankAccounts(state) {
            return state.bankAccounts
        },
        documents(state) {
            return state.documents
        },
        files(state) {
            return state.files
        }
    },
    mutations: {
        setIsLoading(state, {value}) {
            state.isLoading = value
        },
        updateShortInfo(state, {payload}) {
            state.shortInfo = payload
        },
        updateProfileBasicInfo(state, {payload}) {
            state.profileBasicInfo = payload
        },
        updateProfileEntrepreneurInfo(state, {payload}) {
            state.profileEntrepreneurInfo = payload
        },
        updateEntrepreneurInfo(state, { field, value }) {
            state.profileEntrepreneurInfo[field] = value
        },
        addNewEntrepreneurPhone(state) {
            state.profileEntrepreneurInfo.phones.push({id: null, phone: '', phone_type: HOME_PHONE})
        },
        updateEntrepreneurPhone(state, { index, field, value }) {
            state.profileEntrepreneurInfo.phones[index][field] = value
        },
        removeEntrepreneurPhone(state, { index }) {
            let newArray = []
            let lastArray = state.profileEntrepreneurInfo.phones
            for (let i = 0; i < lastArray.length; i++) {
                if (i !== index) {
                    newArray.push(lastArray[i])
                }
            }
            state.profileEntrepreneurInfo.phones = newArray
        },
        resetBankAccounts(state) {
            state.bankAccounts = []
        },
        updateBankAccounts(state, { results }) {
            state.bankAccounts = results
        },
        resetDocuments(state) {
            state.documents = []
        },
        updateDocuments(state, { results }) {
            state.documents = results
        },
        resetFiles(state) {
            state.files = []
        },
        updateFiles(state, { results }) {
            state.files = results
        }
    },
    actions: {
        async loadShortInfo({commit}) {
            await user.getShortInfo()
                .then(response => {
                    switch (response.status) {
                        case 200:
                            commit('updateShortInfo', {payload: response.data})
                    }
                })
        },
        async loadProfileBasicInfo() {
            return await user.getProfileBasicInfo()
        },
        async updateProfileBasicInfo({commit}, payload) {
            commit('setIsLoading', {value: true})
            return await user.updateProfileBasicInfo(payload)
        },
        async loadProfileEntrepreneurInfo() {
            return await user.getProfileEntrepreneurInfo()
        },
        async updateProfileEntrepreneurInfo({state, commit}) {
            commit('setIsLoading', {value: true})
            return await user.updateProfileEntrepreneurInfo({
                ...state.profileEntrepreneurInfo,
                registration_address_data: state.profileEntrepreneurInfo.registration_address,
                phones_data: state.profileEntrepreneurInfo.phones
            })
        },
        async getAllBankAccounts({commit}, payload) {
            commit('resetBankAccounts')
            commit('setIsLoading', {value: true})
            return await user.getAllBankAccounts(payload.limit, payload.offset)
        },
        async getAllBankAccountsWidget() {
            return await user.getAllBankAccountsWidget(100, 0)
        },
        async removeBankAccount({commit}, bankAccountId) {
            commit('setIsLoading', {value: true})
            return await user.removeBankAccount(bankAccountId)
        },
        async getBankAccountDetail({commit}, bankAccountId) {
            commit('setIsLoading', {value: false})
            return await user.getBankAccountDetail(bankAccountId)
        },
        async updateBankAccountDetail({commit}, {bankAccountId, payload}) {
            commit('setIsLoading', {value: false})
            return await user.updateBankAccountDetail(bankAccountId, payload)
        },
        async createBankAccountDetail({commit}, payload) {
            commit('setIsLoading', {value: false})
            return await user.createBankAccountDetail(payload)
        },
        async getAllDocuments({commit}, payload) {
            commit('resetDocuments')
            commit('setIsLoading', {value: true})
            return await user.getAllDocuments(payload.limit, payload.offset)
        },
        async removeDocument({commit}, documentId) {
            commit('setIsLoading', {value: true})
            return await user.removeDocument(documentId)
        },
        async getDocumentDetail({commit}, documentId) {
            commit('setIsLoading', {value: false})
            return await user.getDocumentDetail(documentId)
        },
        async updateDocumentDetail({commit}, {documentId, payload}) {
            commit('setIsLoading', {value: false})
            return await user.updateDocumentDetail(documentId, payload)
        },
        async createDocumentDetail({commit}, payload) {
            commit('setIsLoading', {value: false})
            return await user.createDocument(payload)
        },
        async getAllFiles({commit}, payload) {
            commit('resetFiles')
            commit('setIsLoading', {value: true})
            return await user.getAllFiles(payload.limit, payload.offset)
        },
        async removeFile({commit}, fileId) {
            commit('setIsLoading', {value: true})
            return await user.removeFile(fileId)
        },
        async createFileDetail({commit}, payload) {
            commit('setIsLoading', {value: false})
            return await user.createFile(payload)
        }
    }
}
