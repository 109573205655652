export function processPaginatorResults(current, limit, payload) {
    let paginator = {
        limit: limit,
        current: current,
        next: null,
        previous: null,
        allPages: 0
    }
    if (payload.count > 0) {
        paginator.allPages = Math.ceil(payload.count / limit);
    }
    if (payload.next) {
        paginator.next = current + 1;
    }
    if (payload.previous) {
        paginator.previous = current - 1;
    }
    return paginator
}


export function toFormAnyBackendLink(path) {
    return `${process.env.VUE_APP_BACKEND_URL}${path}`
}


export function copyTextToClipboard(value) {
  navigator.clipboard.writeText(value)
}

export function parseDateFromStr(date) {
  const [day, month, year] = date.split('.')
  return (new Date(new Date(`${month}.${day}.${year}`) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
}

export function formatDateStr (date) {
  if (!date) return null

  const [year, month, day] = date.split('-')
  return `${day}.${month}.${year}`
}