import { session, setAuthHeader } from '@/api/session'

export default {
  getAllStyles (limit, offset) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/template_style/?limit=${limit}&offset=${offset}`
    return session.get(url, setAuthHeader())
  },
  getStyleDetail (styleId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/template_style/${styleId}/`
    return session.get(url, setAuthHeader())
  },
  createStyle (payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/template_style/`
    return session.post(url, payload, setAuthHeader())
  },
  removeStyle (styleId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/template_style/${styleId}/`
    return session.delete(url, setAuthHeader())
  },
  saveStyleDetail (styleId, payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/template_style/${styleId}/`
    return session.put(url, payload, setAuthHeader())
  },
}
