import { session, setAuthHeader } from '@/api/session'

export default {
  getDictionary (name) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/dictionary/?dictionary=${name}`
    return session.get(url, setAuthHeader())
  },
  getBanks() {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/bank/`
    return session.get(url, setAuthHeader())
  },
  getPaymentCategories(limit, offset, paymentType) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/payment_category/?limit=${limit}&offset=${offset}&payment_type=${paymentType}`
    return session.get(url, setAuthHeader())
  }
}
