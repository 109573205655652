import template from '@/api/template'

const getDefaultState = () => ({
    isLoading: false,
    templates: [],
    templateId: null
})

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        templates(state) {
            return state.templates
        }
    },
    mutations: {
        setIsLoading(state, { value }) {
            state.isLoading = value
        },
        resetTemplates(state) {
            state.templates = []
            state.templateId = null
        },
        updateTemplates(state, { results }) {
            state.templates = results
        },
        resetTemplate(state, { templateId }) {
            state.templateId = templateId
        }
    },
    actions: {
        async getAllTemplates({commit}, payload) {
            commit('resetTemplates')
            commit('setIsLoading', {value: true})
            return await template.getAllTemplates(payload.limit, payload.offset)
        },
        async getTemplateDetail({commit}, templateId) {
            commit('resetTemplate', templateId)
            commit('setIsLoading', {value: true})
            return await template.getTemplateDetail(templateId)
        },
        async saveTemplateDetail({commit}, {templateId, payload}) {
            commit('setIsLoading', {value: true})
            return await template.saveTemplateDetail(templateId, payload)
        },
        async generatePreviewToPdf({commit}, templateId) {
            commit('setIsLoading', {value: true})
            return await template.generatePreviewToPdf(templateId)
        },
        async updateVersion({commit}, templateId) {
            commit('setIsLoading', {value: true})
            return await template.updateVersion(templateId)
        },
        async loadPreviewHtml({commit}, templateId) {
            commit('setIsLoading', {value: true})
            return await template.loadPreviewHtml(templateId)
        },
        async createTemplate({commit}, payload) {
            commit('setIsLoading', {value: true})
            return await template.createTemplate(payload)
        },
        async removeTemplate({commit}, templateId) {
            commit('setIsLoading', {value: true})
            return await template.removeTemplate(templateId)
        }
    }
}
