import { session, setAuthHeader } from '@/api/session'

export default {
  getShortInfo() {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}accounts/profile/short_info/`
    return session.get(url, setAuthHeader())
  },
  getProfileBasicInfo() {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}accounts/profile/basic_info/`
    return session.get(url, setAuthHeader())
  },
  updateProfileBasicInfo(payload) {
    let config = setAuthHeader()
    config.headers['Content-Type'] = 'multipart/form-data;'
    let url = `${process.env.VUE_APP_BACKEND_API_URL}accounts/profile/basic_info/`
    return session.put(url, payload, config)
  },
  getProfileEntrepreneurInfo() {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}accounts/profile/entrepreneur_info/`
    return session.get(url, setAuthHeader())
  },
  updateProfileEntrepreneurInfo(payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}accounts/profile/entrepreneur_info/`
    return session.put(url, payload, setAuthHeader())
  },
  getAllBankAccounts(limit, offset) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/bank_account/?limit=${limit}&offset=${offset}`
    return session.get(url, setAuthHeader())
  },
  getAllBankAccountsWidget(limit, offset) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/bank_account/?limit=${limit}&offset=${offset}&widget=fk`
    return session.get(url, setAuthHeader())
  },
  removeBankAccount(bankAccountId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/bank_account/${bankAccountId}/`
    return session.delete(url, setAuthHeader())
  },
  getBankAccountDetail(bankAccountId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/bank_account/${bankAccountId}/`
    return session.get(url, setAuthHeader())
  },
  updateBankAccountDetail(bankAccountId, payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/bank_account/${bankAccountId}/`
    return session.put(url, payload, setAuthHeader())
  },
  createBankAccountDetail(payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/bank_account/`
    return session.post(url, payload, setAuthHeader())
  },
  getAllDocuments(limit, offset) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}accounts/document/?limit=${limit}&offset=${offset}`
    return session.get(url, setAuthHeader())
  },
  getDocumentDetail(documentId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}accounts/document/${documentId}/`
    return session.get(url, setAuthHeader())
  },
  updateDocumentDetail(documentId, payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}accounts/document/${documentId}/`
    return session.put(url, payload, setAuthHeader())
  },
  createDocument(payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}accounts/document/`
    return session.post(url, payload, setAuthHeader())
  },
  removeDocument(documentId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}accounts/document/${documentId}/`
    return session.delete(url, setAuthHeader())
  },
  getAllFiles(limit, offset) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}accounts/file/?limit=${limit}&offset=${offset}`
    return session.get(url, setAuthHeader())
  },
  createFile(payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}accounts/file/`
    return session.post(url, payload, setAuthHeader())
  },
  removeFile(fileId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}accounts/file/${fileId}/`
    return session.delete(url, setAuthHeader())
  },
}
