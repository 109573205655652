import { session, setAuthHeader } from '@/api/session'

export default {
  getAllPayments(limit, offset, bankAccountId, paymentType, startDate, endDate) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/payment/?limit=${limit}&offset=${offset}&bank_account=${bankAccountId}&payment_type=${paymentType}&start_date=${startDate}&end_date=${endDate}`
    return session.get(url, setAuthHeader())
  },
  createPayment(payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/payment/`
    return session.post(url, payload, setAuthHeader())
  },
  updatePayment(paymentId, payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/payment/${paymentId}/`
    return session.put(url, payload, setAuthHeader())
  },
  updatePaymentStatus(paymentId, status) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/payment/${paymentId}/`
    return session.patch(url, {status: status}, setAuthHeader())
  },
  removePayment(paymentId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/payment/${paymentId}/`
    return session.delete(url, setAuthHeader())
  },
  getPaymentDetail(paymentId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/payment/${paymentId}/`
    return session.get(url, setAuthHeader())
  }
}
