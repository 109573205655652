import contract from '@/api/contract'

const getDefaultState = () => ({
    isLoading: false,
    contracts: [],
})

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        contracts(state) {
            return state.contracts
        }
    },
    mutations: {
        setIsLoading(state, { value }) {
            state.isLoading = value
        },
        resetContracts(state) {
            state.contracts = []
        },
        updateContracts(state, { results }) {
            state.contracts = results
        }
    },
    actions: {
        async getAllContracts({commit}, payload) {
            commit('resetContracts')
            commit('setIsLoading', {value: true})
            return await contract.getAllContracts(payload.limit, payload.offset)
        }
    }
}
