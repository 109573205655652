import { session, setAuthHeader } from '@/api/session'

export default {
  getAllInvoices (limit, offset) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/invoice/?limit=${limit}&offset=${offset}`
    return session.get(url, setAuthHeader())
  },
  getInvoiceDetail (invoiceId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/invoice/${invoiceId}/`
    return session.get(url, setAuthHeader())
  },
  updateInvoiceStatus (invoiceId, status) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/invoice/${invoiceId}/`
    return session.patch(url, {status: status}, setAuthHeader())
  },
  runAmountCalculation (invoiceId, payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/invoice/${invoiceId}/amount_calculation/`
    return session.post(url, payload, setAuthHeader())
  },
  createNewInvoice (payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/invoice/`
    return session.post(url, payload, setAuthHeader())
  },
  removeInvoice (invoiceId) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/invoice/${invoiceId}/`
    return session.delete(url, setAuthHeader())
  },
  saveInvoiceDetail (invoiceId, payload) {
    let url = `${process.env.VUE_APP_BACKEND_API_URL}commons/invoice/${invoiceId}/`
    return session.put(url, payload, setAuthHeader())
  }
}
