export const INVOICE_STATUS_DRAFT = 0;
export const INVOICE_STATUS_CREATED = 1;
export const INVOICE_STATUS_SENT = 2;
export const INVOICE_STATUS_PAID = 3;
export const INVOICE_STATUS_NOT_PAID = 4;

export const INVOICE_RATE_BY_MONTH = 0;
export const INVOICE_RATE_BY_HOUR = 1;

export const DEFAULT_USER_AVATAR_LINK = 'https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-1.jpg';

export const GENDER_TYPE_MALE = 0;
export const GENDER_TYPE_FEMALE = 1;
export const GENDER_TYPE_OTHER = 2;

export const GENDERS_CHOOSES = [
    {
        key: GENDER_TYPE_MALE,
        text: 'Male'
    },
    {
        key: GENDER_TYPE_FEMALE,
        text: 'Female'
    },
    {
        key: GENDER_TYPE_OTHER,
        text: 'Other'
    },
]


export const ENTREPRENEUR_GROUP_3 = 3;
export const ENTREPRENEUR_GROUP_CHOOSES = [
    {
        key: ENTREPRENEUR_GROUP_3,
        text: '3 group'
    },
]

export const ENTREPRENEUR_SIMPLIFIED_TAXATION = 0;
export const ENTREPRENEUR_FULL_TAXATION = 1;
export const ENTREPRENEUR_TAXATION_SYSTEM_CHOOSES = [
    {
        key: ENTREPRENEUR_SIMPLIFIED_TAXATION,
        text: 'Simplified'
    },
    {
        key: ENTREPRENEUR_FULL_TAXATION,
        text: 'Full taxation'
    },
]


export const ENTREPRENEUR_TAX_RATE_5 = 5;
export const ENTREPRENEUR_TAX_RATE_3 = 3;
export const ENTREPRENEUR_TAX_RATE_2 = 2;
export const ENTREPRENEUR_TAX_RATE_CHOOSES = [
    {
        key: ENTREPRENEUR_TAX_RATE_5,
        text: '5%'
    },
    {
        key: ENTREPRENEUR_TAX_RATE_3,
        text: '3%'
    },
    {
        key: ENTREPRENEUR_TAX_RATE_2,
        text: '2%'
    },
]

export const MAIN_PHONE = 0;
export const HOME_PHONE = 1;
export const OTHER_PHONE = 2;
export const PHONE_TYPES_CHOOSES = [
    {
        key: MAIN_PHONE,
        text: 'Main'
    },
    {
        key: HOME_PHONE,
        text: 'Home'
    },
    {
        key: OTHER_PHONE,
        text: 'Other'
    },
]


export const UAH = 980;
export const USD = 840;
export const EUR = 978;
export const CURRENCIES_CHOOSES = [
    {
        key: UAH,
        text: 'UAH'
    },
    {
        key: USD,
        text: 'USD'
    },
    {
        key: EUR,
        text: 'EUR'
    },
]


export const BUSINESS_BANK_ACCOUNT = 0;
export const DEFAULT_BANK_ACCOUNT = 1;
export const DEPOSIT_BANK_ACCOUNT = 2;
export const CREDIT_BANK_ACCOUNT = 3;
export const BANK_ACCOUNTS_CHOOSES = [
    {
        key: BUSINESS_BANK_ACCOUNT,
        text: 'Business'
    },
    {
        key: DEFAULT_BANK_ACCOUNT,
        text: 'Default'
    },
    {
        key: DEPOSIT_BANK_ACCOUNT,
        text: 'Deposit'
    },
    {
        key: CREDIT_BANK_ACCOUNT,
        text: 'Credit'
    },
]


export const BANK_ACCOUNT_ACTIVE = 0;
export const BANK_ACCOUNT_INACTIVE = 1;
export const BANK_ACCOUNTS_STATUS_CHOOSES = [
    {
        key: BANK_ACCOUNT_ACTIVE,
        text: 'Active'
    },
    {
        key: BANK_ACCOUNT_INACTIVE,
        text: 'Inactive'
    }
]

export const PASSPORT_BOOK = 0;
export const PASSPORT_ID_CARD = 1;
export const DOCUMENTS_CHOOSES = [
    {
        key: PASSPORT_BOOK,
        text: 'Passport of Ukraine'
    },
    {
        key: PASSPORT_ID_CARD,
        text: 'Passport of a citizen of Ukraine in the form of an ID card'
    }
]

export const ENTREPRENEUR_FILE_TYPE_EXTRACT = 0;
export const ENTREPRENEUR_FILE_TYPE_OTHER = 1;
export const ENTREPRENEUR_FILE_TYPE_CHOOSES = [
    {
        key: ENTREPRENEUR_FILE_TYPE_EXTRACT,
        text: 'Extract from the Unified State Register'
    },
    {
        key: ENTREPRENEUR_FILE_TYPE_OTHER,
        text: 'Other'
    }
]

export const PAYMENT_TYPE_INCOME = 0;
export const PAYMENT_TYPE_EXPENSES = 1;

export const PAYMENT_TYPE_CHOOSES = [
    {
        key: PAYMENT_TYPE_INCOME,
        text: 'Income'
    },
    {
        key: PAYMENT_TYPE_EXPENSES,
        text: 'Expenses'
    }
]

export const PAYMENT_STATUS_CREATED = 0;
export const PAYMENT_STATUS_IN_PROCESSING = 1;
export const PAYMENT_STATUS_CONFIRMED = 2;

export const PAYMENT_STATUS_CHOOSES = [
    {
        key: PAYMENT_STATUS_CREATED,
        text: 'Created'
    },
    {
        key: PAYMENT_STATUS_IN_PROCESSING,
        text: 'In processing'
    },
    {
        key: PAYMENT_STATUS_CONFIRMED,
        text: 'Confirmed'
    }
]




