import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSpinners from 'vue-spinners'
import VueMask from 'v-mask'
import VueToastr from "vue-toastr"
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'


Vue.use(VueMask)
Vue.use(VueSpinners)
Vue.use(VueAxios, axios)
Vue.component('PrismEditor', PrismEditor)
Vue.use(VueToastr, {
  defaultPosition: 'toast-top-right',
  defaultTimeout: 3000
})

Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
