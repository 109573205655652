import finance from '@/api/finance'

const getDefaultState = () => ({
    isLoading: false,
    filterForm: {
        bankAccount: null,
        periodFrom: null,
        periodTo: null,
        paymentType: null
    },
    payments: [],
})

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        payments(state) {
            return state.payments
        },
        filterForm(state) {
            return state.filterForm
        }
    },
    mutations: {
        setIsLoading(state, {value}) {
            state.isLoading = value
        },
        resetPayments(state) {
            state.payments = []
        },
        updatePayments(state, {results}) {
            state.payments = results
        },
        updateFilterForm(state, {field, value}) {
            state.filterForm[field] = value
        }
    },
    actions: {
        async getAllPayments({state, commit}, payload) {
            commit('resetPayments')
            commit('setIsLoading', {value: true})
            return await finance.getAllPayments(payload.limit, payload.offset, state.filterForm.bankAccount, state.filterForm.paymentType, state.filterForm.periodFrom, state.filterForm.periodTo)
        },
        async createPayment({commit}, payload) {
            commit('setIsLoading', {value: true})
            return await finance.createPayment(payload)
        },
        async updatePayment({commit}, {paymentId, payload}) {
            commit('setIsLoading', {value: true})
            return await finance.updatePayment(paymentId, payload)
        },
        async updatePaymentStatus({commit}, {paymentId, status}) {
            commit('setIsLoading', {value: true})
            return await finance.updatePaymentStatus(paymentId, status)
        },
        async removePayment({commit}, {paymentId}) {
            commit('setIsLoading', {value: true})
            return await finance.removePayment(paymentId)
        },
        async getPaymentDetail({commit}, {paymentId}) {
            commit('setIsLoading', {value: true})
            return await finance.getPaymentDetail(paymentId)
        }
    }
}
