import style from '@/api/style'


const getDefaultState = () => ({
    isLoading: false,
    styles: [],
    styleId: null
})

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        isLoading(state) {
            return state.isLoading
        },
        styles(state) {
            return state.styles
        }
    },
    mutations: {
        setIsLoading(state, { value }) {
            state.isLoading = value
        },
        resetStyles(state) {
            state.styles = []
            state.statusId = null
        },
        updateStyles(state, { results }) {
            state.styles = results
        },
        resetStyle(state, { statusId }) {
            state.statusId = statusId
        }
    },
    actions: {
        async getAllStyles({commit}, payload) {
            commit('resetStyles')
            commit('setIsLoading', {value: true})
            return await style.getAllStyles(payload.limit, payload.offset)
        },
        async getStyleDetail({commit}, styleId) {
            commit('resetStyle', styleId)
            commit('setIsLoading', {value: true})
            return await style.getStyleDetail(styleId)
        },
        async createStyle({commit}, payload) {
            commit('setIsLoading', {value: true})
            return await style.createStyle(payload)
        },
        async removeStyle({commit}, styleId) {
            commit('setIsLoading', {value: true})
            return await style.removeStyle(styleId)
        },
        async saveStyleDetail({commit}, {styleId, payload}) {
            commit('setIsLoading', {value: true})
            return await style.saveStyleDetail(styleId, payload)
        }
    }
}
